import { provideHttpClient,
  withFetch,
  withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import { MessageService } from 'primeng/api';
import { routes } from './app.route';
import { authInterceptor } from './shared/interceptors/auth-interceptor';
import { httpErrorNotificationInterceptor } from './shared/interceptors/http-error-notification-interceptor';

export const httpInterceptors = [
  authInterceptor,
  httpErrorNotificationInterceptor,
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors(httpInterceptors)),
    provideClientHydration(),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js'),
    MessageService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
